<script async setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { computed, Ref } from 'vue';

import { UserDetailsDataInterface } from '@/models/UserDetails';
import { getTimeOfDayGreeting } from '@/services/utils/timeOfDayGreeting';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

const userStore = useUserStore();
const userDetails: Ref<UserDetailsDataInterface | null> = computed(() => userStore.userDetails);

const { translate } = useLocaleTranslator();

const dynamicGreeting = getTimeOfDayGreeting();
</script>

<template>
  <Suspense>
    <Stack class="wk-goodmorning" :gap="0">
      <Typography :variant="TypographyVariants.H1" :bold="false"
        >{{ dynamicGreeting }} {{ userDetails?.firstName }}</Typography
      >
      <Typography :variant="TypographyVariants.Paragraph">{{ translate('greeting.subtitle') }}</Typography>
    </Stack>
  </Suspense>
</template>

<style lang="scss" scoped>
:deep(.wk-typography-h1) {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
  margin-bottom: 0.5rem;

  @include mq(xs) {
    font-size: 2.5rem;
  }
}

:deep(.wk-typography-p) {
  display: none;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 300;
  color: $wk-gray-shade2;

  @include mq(xs) {
    display: inline;
  }
}

.wk- .wk-greeting-wrapper {
  padding: 3rem 0;
}
.wk-greeting {
  font-size: 3rem;
  font-weight: 400;
}
.wk-subgreeting {
  font-size: 1.2rem;
}
</style>
