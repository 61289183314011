<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { AxiosError } from 'axios';
import { ref } from 'vue';

import { ErrorReturnType } from '@/models/Error';
import { EventType, SourceFeedbackEventData } from '@/models/Event';
import { FeedbackValue, ThumbButtonsState, ThumbFeedbackType } from '@/models/Feedback';
import { Source } from '@/models/Message';
import { handleApiError, sa_api } from '@/services/api/api';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';

import ThumbsDown from './ThumbsDown.vue';
import ThumbsUp from './ThumbsUp.vue';

interface Props {
  source: Source;
}

interface InputInterface {
  score_type: string;
  score_value: string;
  trace_id: string;
  source_type: string;
  source_id: number;
  source_title: string;
}

const props = defineProps<Props>();

const activeButton = ref<ThumbButtonsState>(ThumbButtonsState.None);
const sourceFeedbackEvent: Event<SourceFeedbackEventData> = new Event().setType(EventType.SourceFeedback);

const handleButtonClick = (button: ThumbButtonsState) => {
  activeButton.value = button;
  sendEventToAnalytics();
  sendDataToBackend();
  return false;
};

const sendEventToAnalytics = () => {
  sourceFeedbackEvent.setData({
    trace_id: props.source.trace_id,
    value: FeedbackValue[activeButton.value],
    content_type: props.source.content_type,
    content_id: props.source.content_id
  });

  dataLayer.push(sourceFeedbackEvent);
};

const sendDataToBackend = async () => {
  const input: InputInterface = {
    score_type: ThumbFeedbackType.Source,
    score_value: FeedbackValue[activeButton.value],
    trace_id: props.source.trace_id,
    source_type: props.source.content_type,
    source_id: props.source.content_id,
    source_title: props.source.title
  };

  await sa_api
    .post('/chat/user-feedback', { input: input })
    .then(() => {
      return null;
    })
    .catch((error: AxiosError) => {
      handleApiError(error, ErrorReturnType.Notification);
    });

  return false;
};
</script>

<template>
  <Cluster class="feedback" :gap="0">
    <Typography class="label" :variant="TypographyVariants.Paragraph">
      <slot />
    </Typography>
    <ThumbsUp :clickedButton="activeButton" :onButtonClicked="handleButtonClick" />
    <ThumbsDown :clickedButton="activeButton" :onButtonClicked="handleButtonClick" />
  </Cluster>
</template>

<style scoped lang="scss">
.feedback {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 0.75rem;
  color: $wk-gray-tint1;
  position: relative;

  .label {
    margin-right: 0.5rem;
  }
}
</style>
