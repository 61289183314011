<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { ComponentPublicInstance, computed, onMounted, onUnmounted, ref } from 'vue';

import ConversationMeta from '@/components/conversation/ConversationMeta.vue';
import MoreInputBar from '@/components/conversation/MoreInputBar.vue';
import { getDomainName } from '@/models/Domain';
import { Role } from '@/models/Message';
import { useThreadStore } from '@/stores/thread';

import Question from './Question.vue';
import Response from './response/Response.vue';

const thread = useThreadStore();
const convElem = ref<ComponentPublicInstance>();
const messages = computed(() => thread.messages);
const oldNumberOfLoadedResponses = ref(0);

const scrollToLastResponse = (entries: ResizeObserverEntry[]) => {
  const lastQuestionElement = Array.from(entries[0].target.querySelectorAll('div.question')).at(-1);
  const loadedResponseElements = Array.from(entries[0].target.querySelectorAll('div.response-content'));
  const allResponsesLoadeded = loadedResponseElements.length !== oldNumberOfLoadedResponses.value;

  // only scroll when a response is added, other scroll behaviour is implemented elsewhere
  if (allResponsesLoadeded && lastQuestionElement)
    (lastQuestionElement as HTMLElement).scrollIntoView({ block: 'start' });
  oldNumberOfLoadedResponses.value = loadedResponseElements.length;
};
const resizeObserver = new ResizeObserver(scrollToLastResponse);

onMounted(() => resizeObserver.observe(convElem.value?.$el));

onUnmounted(() => convElem.value?.$el instanceof Element && resizeObserver.unobserve(convElem.value?.$el));
</script>

<template>
  <div>
    <Stack ref="convElem" class="conversation" :gap="0">
      <ConversationMeta
        :key="thread.uuid || 'meta'"
        :domain="getDomainName(thread.domain)"
        :createdAt="thread.created_at"
        :loading="!thread.hasAssistantResponded"
      />
      <div
        v-for="(message, index) in messages"
        :key="message.uuid || index"
        :class="message.role === Role.Human ? 'question' : 'response'"
      >
        <component :is="message.role === Role.Human ? Question : Response" :message="message" />
      </div>
    </Stack>
    <MoreInputBar v-if="!thread.readOnly" :key="1" />
  </div>
</template>

<style scoped lang="scss">
.conversation {
  min-height: calc(100vh - 12.25rem);
  padding-bottom: 9rem;

  @include mq(xs) {
    padding-left: 0;
    padding-bottom: 0;
  }
}
.response {
  @include mq(sm) {
    padding-bottom: 1.5rem;
  }

  &:has(+ .question) {
    margin-bottom: 1.5rem;
    border-bottom: solid 1px $wk-gray-tint4;
  }
}
</style>
